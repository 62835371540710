import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import ClaimStatusAvatar from '../../../components/Avatars/ClaimStatusAvatar/ClaimStatusAvatar';
import { formatDate } from '../../utils/formatDate';
import { currencyFormat } from '../../utils/currencyFormat';
import { onClaimViewClick } from '../helpers/rowActions';
import { setRequestHistory } from './setRequestHistory';
import { t } from '../../../service/localization/i18n';
import { formatMoney } from '../../../Helper';
import moment from 'moment';
import { patientFullNameWithAge } from '../../utils/patientFullName';
import NotesCounter from '../components/ClaimDetails/NotesCounter';

export const rowTemplate = (data, field, isFetching, history, dispatch, setHeader, setClaimHistory, setPopupVisible) => {
  const contentSize = { minHeight: '25px' };
  const recordIdTooltip = t('Record_Id_Tooltip');
  const tooltipStyles = { maxWidth: '300px' };

  // eslint-disable-next-line default-case
  if (data) {
    switch (field) {
      case 'dos':
        return (
          <>
            {isFetching ? (
              <Skeleton width="90%" />
            ) : (
              <>
                <Tooltip target=".dos" position="top" />
                <div className="dos flex align-items-center" data-pr-tooltip={t('DOS')}>
                  {formatDate(data.ServiceDate)}
                </div>
              </>
            )}
          </>
        );

      case 'fullname':
        const dob = moment(data.BirthDay).format('DD/MM/YYYY');
        const patientTooltip = `DOB: ${dob}, PHN: ${data.PHN}`;
        return (
          <>
            {isFetching ? (
              <Skeleton width="90%" />
            ) : (
              <>
                <Tooltip target=".fullname" position="top" style={{ width: '130px' }} />
                <div className="fullname flex align-items-center" data-pr-tooltip={patientTooltip}>
                  {patientFullNameWithAge(data)}
                </div>
              </>
            )}
          </>
        );

      case 'type':
        return (
          <>
            {isFetching ? (
              <Skeleton width="90%" />
            ) : (
              <>
                <Tooltip target=".type" position="top" style={tooltipStyles} />
                <div className="type flex align-items-center" style={contentSize} data-pr-tooltip={t('Payer')}>
                  {data.ClaimType === 'R' ? 'F8/11' : data.RecType}
                </div>
              </>
            )}
          </>
        );

      case 'icd9':
        const title = data.ICD9Note ? `${data.ICD9Note}: ${data.ICD9Text}` : data.ICD9Text;
        const code2 = data.ICD9Code2 ? `${data.ICD9Code2}` : '';
        const code3 = data.ICD9Code3 ? `${data.ICD9Code3}` : '';
        const codes = `${data.ICD9Code}${code2}${code3}`;

        return (
          <>
            {isFetching ? (
              <Skeleton width="90%" />
            ) : (
              <>
                <Tooltip target=".icd9" position="top" style={tooltipStyles} />
                <div className="icd9 flex align-items-center" style={contentSize} data-pr-tooltip={title}>
                  {codes.replaceAll(',', ', ')}
                </div>
              </>
            )}
          </>
        );

      case 'feecode':
        const privateService = data?.LineCount === 1 ? (data?.LineDetails ? data?.LineDetails[0]?.Name : '') : t('Multiple');
        return (
          <>
            {isFetching ? (
              <Skeleton width="90%" />
            ) : (
              <>
                <Tooltip target=".feecode" position="top" style={tooltipStyles} />

                {data?.InvoiceType === 'Private' ? (
                  <div className="feecode flex align-items-center" style={contentSize} data-pr-tooltip={privateService}>
                    {privateService}
                  </div>
                ) : (
                  <div className="feecode flex align-items-center" style={contentSize} data-pr-tooltip={data.FeeCodeText}>
                    {data.FeeCode}
                  </div>
                )}
              </>
            )}
          </>
        );

      case 'fee':
        //const fee = formatMoney(data.FeeAmount * data.Units)
        const fee = formatMoney(data.FeeTotal);
        return (
          <>
            {isFetching ? (
              <Skeleton width="90%" />
            ) : (
              <>
                <Tooltip target=".fee" position="top" style={tooltipStyles} />
                <div className="flex justify-content-start">
                  <div className="fee flex justify-content-end" data-pr-tooltip={`${t('Billed by')} ${data.TreatedBy}`} style={{ minWidth: '60px' }}>
                    {fee}
                  </div>
                </div>
              </>
            )}
          </>
        );

      case 'paid':
        return (
          <>
            {isFetching ? (
              <Skeleton width="90%" />
            ) : (
              <>
                <Tooltip target=".paid" position="top" style={tooltipStyles} />
                <div className="flex justify-content-start">
                  <div
                    className="fee flex justify-content-end"
                    data-pr-tooltip={`${t('Paid to')} ${data.PayeeNumber ? data.PayeeNumber : data.TreatedBy}`}
                    style={{ minWidth: '60px' }}
                  >
                    {currencyFormat(data.FeePaid)}
                  </div>
                </div>
              </>
            )}
          </>
        );

      case 'due':
        return (
          <>
            {isFetching ? (
              <Skeleton width="90%" />
            ) : (
              <>
                <Tooltip target=".due" position="top" style={tooltipStyles} />
                <div className="flex justify-content-start">
                  <div className="fee flex justify-content-end" data-pr-tooltip={t('Due')} style={{ minWidth: '60px' }}>
                    {currencyFormat(data.FeeDue)}
                  </div>
                </div>
              </>
            )}
          </>
        );

      case 'status':
        return (
          <>
            {isFetching ? (
              <Skeleton shape="circle" size="3rem" />
            ) : (
              <>
                <div className="status flex col-12 p-0 align-items-center pointer">
                  <div className="col-6 p-0" onClick={() => onClaimViewClick(data, history)}>
                    <ClaimStatusAvatar status={data.Status} statusText={data.StatusText} style={{ width: '3em', height: '3em' }} />
                  </div>
                  <div className="col-6 p-0">
                    {data.RemDiff >= 1 && (
                      <Button
                        className="p-button-rounded p-button-text"
                        icon="pi pi-exclamation-circle"
                        tooltip={t('Paid_with_adjustment')}
                        TooltipOptions={{ position: 'top' }}
                        onClick={() => setRequestHistory(data, setClaimHistory, setPopupVisible, setHeader, dispatch)}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        );

      case 'recordnumber':
        if (data.SequenceNumber) {
          return (
            <>
              {isFetching ? (
                <Skeleton shape="circle" size="3rem" className="mr-2" />
              ) : (
                <>
                  <Tooltip target=".recordnumberS" position="top" style={tooltipStyles} />
                  <div className="flex align-items-center justify-content-between gap-3">
                    <div className="recordnumberS flex align-items-center" style={contentSize} data-pr-tooltip={recordIdTooltip}>
                      {data.SequenceNumber} (s)
                    </div>
                    <NotesCounter totalNotes={data.TotalNotes} invoiceGuid={data.InvoiceGuid} />
                  </div>
                </>
              )}
            </>
          );
        } else {
          return (
            <>
              {isFetching ? (
                <Skeleton width="90%" />
              ) : (
                <>
                  <Tooltip target=".recordnumberO" position="top" style={tooltipStyles} />
                  <div className="flex align-items-center justify-content-between gap-3">
                    <div className="recordnumberO flex align-items-center" style={contentSize} data-pr-tooltip={recordIdTooltip}>
                      {data.RecordNo} (o)
                    </div>
                    <NotesCounter totalNotes={data.TotalNotes} invoiceGuid={data.InvoiceGuid} />
                  </div>
                </>
              )}
            </>
          );
        }

      default:
        break;
    }
  }
  return <div></div>;
};
