import { store } from './store';
import axios from 'axios';
import { logout } from './modules/auth/actions/auth.actions.creators';

// Contacts
export const CONTACT_PHONE_1 = '604 456-6950';
export const CONTACT_PHONE_2 = '604 456-6950';
export const CONTACT_PHONE_3 = '1 866 456-6950';
export const CONTACT_PHONE_4 = '1 250 405-3592';
export const CONTACT_PHONE_5 = '1 250 405-3593';
export const CONTACT_PHONE_6 = '604 446-6903';
export const CONTACT_PHONE_7 = '604 626-9903';

export const FAX_NUMBER_1 = '1 866 863-6217';
export const FAX_NUMBER_2 = '250-405-3591';

export const CLAIM_MANAGER_CONTACT_EMAIL = 'info@claimmanager.ca';
export const CONTACT_EMAIL_2 = 'provider.program@hibc.gov.bc.ca';

export const VANCOUVER_PHONE = '604 669-6667';
export const VICTORIA_1 = '250-952-3102';
export const VICTORIA_2 = '1 250-383-1226';
export const BS_TOLL_FREE = '1-800-742-6165';

// Dates
export const HOLIDAYS = [
  // 2021
  [
    '01/01/2021',
    '02/15/2021',
    '04/02/2021',
    '04/05/2021',
    '05/24/2021',
    '07/01/2021',
    '08/02/2021',
    '09/06/2021',
    '09/30/2021',
    '10/11/2021',
    '11/11/2021',
    '12/27/2021',
    '12/28/2021'
  ],

  // 2022
  [
    '01/03/2022',
    '02/21/2022',
    '04/15/2022',
    '04/18/2022',
    '05/23/2022',
    '07/01/2022',
    '08/01/2022',
    '09/05/2022',
    '09/30/2022',
    '10/10/2022',
    '11/11/2022',
    '12/26/2022',
    '12/27/2022'
  ],

  // 2023
  [
    '01/02/2023',
    '02/20/2023',
    '04/07/2023',
    '04/10/2023',
    '05/22/2023',
    '07/03/2023',
    '08/07/2023',
    '09/04/2023',
    '10/02/2023',
    '10/09/2023',
    '11/13/2023',
    '12/25/2023',
    '12/26/2023'
  ],

  // 2024
  [
    '01/01/2024',
    '02/19/2024',
    '03/29/2024',
    '04/01/2024',
    '05/20/2024',
    '07/01/2024',
    '08/05/2024',
    '09/02/2024',
    '09/30/2024',
    '10/14/2024',
    '11/11/2024',
    '12/25/2024',
    '12/26/2024'
  ]
];

// URLs
export const REMITTANCE_REPORT_URL = 'https://beta.claimmanager.ca';
export const POLICY_NUMBER_INFO_URL = 'https://secure.claimmanager.ca/media/cards/cards.htm#11';
export const SUBMITTING_TO_A_SECONDARY_INSURER = 'https://plus.telushealth.co/page/eclaims/help/learningcorner/Submitting_to_a_secondary_insurer.htm';
export const AUTORIZATION_FORM = 'https://plus.telussante.co/page/eclaims/resources/asset/pdf/eClaims-Patient-Consent-Form.pdf';
export const E_CLAIM_TERMS_AND_CONDITIONS = 'https://secure.claimmanager.ca/media/eClaimsTerms.htm#panelEPClaimStep2';

const apiURLs = {
  local: 'http://localhost/data/api',
  demo: 'https://api.nopaperwork.ca:844/api',
  beta: 'https://webapi.claimmanager.ca/api',
  showcase: 'https://scapi.claimmanager.ca/api',
  live: 'https://v5.claimmanager.ca/api'
};

const legacyURLs = {
  local: 'http://localhost',
  demo: 'http://beta.claimmanager.ca/',
  beta: 'https://beta.claimmanager.ca',
  showcase: 'https://beta.claimmanager.ca',
  live: 'https://secure.claimmanager.ca/default.aspx'
};

const remittanceReportUrls = {
  local: 'https://beta.claimmanager.ca',
  demo: 'https://beta.claimmanager.ca',
  beta: 'https://beta.claimmanager.ca',
  showcase: 'https://beta.claimmanager.ca',
  live: 'https://secure.claimmanager.ca'
};

const pdfReportUrls = {
  local: 'https://demoprintv5.claimmanager.ca',
  demo: 'https://demoprintv5.claimmanager.ca',
  beta: 'https://demoprintv5.claimmanager.ca',
  live: 'https://printv5.claimmanager.ca'
};

export function getApiBase(mode) {
  if (process.env.REACT_APP_USERDOMAIN === 'VB') return apiURLs['local'];
  if (process.env.REACT_APP_USERDOMAIN === 'demo') return apiURLs['demo'];
  const hostname = document.location.hostname;
  
  if (hostname.indexOf('demo.claimmanager.ca') > -1) return apiURLs['showcase'];
  if (hostname.indexOf('claimmanager.ca') > -1) return apiURLs['live'];
  if (hostname.indexOf('onrender') > -1) return apiURLs['demo'];
  if (hostname.indexOf('vercel.app') > -1) return apiURLs['demo'];
  if (hostname.indexOf('nopaperwork') > -1) return apiURLs['beta'];
  if (mode && mode !== '') {
    return apiURLs[mode];
  } else {
    const state = store.getState();
    const appmode = state.core.appmode || 'local';
    return apiURLs[appmode];
  }
}

export function getPdfReportUrl(mode) {
  if (window.location.hostname !== 'my.claimmanager.ca' && mode === 'live') {
    return pdfReportUrls['beta'];
  }
  return pdfReportUrls[mode];
}

export function getRedirectUrl(mode) {
  const currentState = window?.store?.getState();
  const token = currentState?.user?.details ? currentState.user.details.LegacyToken : '';
  const reply = token === '' ? legacyURLs[mode] : legacyURLs[mode] + `?token=${token}`;
  return reply;
}

export function getRemittanceUrl() {
  // const currentState = state();
  try {
    const currentState = window?.store?.getState();
    const { appmode } = currentState?.core;
    if (window.location.hostname === 'nopaperwork.ca' && appmode === 'live') {
      return remittanceReportUrls['live'];
    }
    if (window.location.hostname !== 'my.claimmanager.ca' && appmode === 'live') {
      return remittanceReportUrls['beta'];
    }
    console.log(`using remittance report base URL for ${appmode} from state`);
    return remittanceReportUrls[appmode];
  } catch (ex) {
    console.log('failed to get remittance report base URL from state');
    return remittanceReportUrls['demo'];
  }
}

export const setAuthHeader = (state) => {
  const currentState = typeof state === 'function' ? state() : state;
  const token = currentState?.user?.details ? currentState.user.details.JwtToken : '';
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const status = error.response.status;
        if (status === 403) {
          store.dispatch(logout());
        }
        return Promise.reject(error);
      }
    );
  }
};
