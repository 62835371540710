import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import TimeLayout from './layouts/TimeLayout';
import TableHeader from './layouts/TableHeader';
import SpanLayout from './layouts/SpanLayout';
import BilledLayout from './layouts/BilledLayout';
import PaidLayout from './layouts/PaidLayout';
import StatusLayout from './layouts/StatusLayout';
import DxCodeLayout from './layouts/DxCodeLayout';
import FeeCodeLayout from './layouts/FeeCodeLayout';
import PaginatorLeft from './layouts/PaginatorLeft';
import CommentLayout from './layouts/CommentLayout';
import PaginatorRight from './layouts/PaginatorRight';
import ServiceDateLayout from './layouts/ServiceDateLayout';
import ReferralCodeLayout from './layouts/ReferralCodeLayout';
import SequensNumberLayout from './layouts/SequensNumberLayout';
import RejectionCodeLayout from './layouts/RejectionCodeLayout';
import ArchiveButton from './layouts/ArchiveButton';
import SubmissionCodeLayout from './layouts/SubmissionCodeLayout';
import CircularProgress from '../../../../Misc/Loader/CircularProgress/CircularProgress';

import { useTable } from '../../hooks/useTable';
import { defaultRequestParams } from '../../helpers/defaultValues';
import { calculateLast } from '../../../../../modules/utils/calculate';
import { useSimilarClaimsListContext } from '../../hooks/useSimilarClaimsListContext';
import { setClaimInfo } from '../../../../../modules/claims/actions/claims.action.creators';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';

const SimilarClaimsTable = () => {
  const dispatch = useDispatch();
  const { state } = useSimilarClaimsListContext();
  const { onSelectionChange, fetchData, onSort, onPage, tableStyle, showArchiveColumn } = useTable();
  const sortField = state.sortby.split('_')[0];
  const sortOrder = state.sortby.split('_')[1] === 'asc' ? 1 : -1;
  const last = calculateLast({ page: state.page, pageSize: state.pageSize, totalRecords: state.totalRecords });

  // Get init data
  useEffect(() => {
    const patientGuid = state.filters?.patient?.PatientGuid;
    const serviceDate = moment(state.claim.ServiceDate).format('YYYY/MM/DD');
    patientGuid &&
      fetchData({
        patientGuid,
        query: {
          FeeCode: state.claim.FeeCode,
          Period: '7',
          showArchived: '',
          PeriodFrom: serviceDate,
          PeriodTo: serviceDate,
          ServiceDate: serviceDate
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sort the claim list to ensure the item with the same id as state.claim.InvoiceGuid is first
  // VER-661 - check comments - How do you sort the claims on the Similar claims panel? Can you please always show the selected claim first and have  it highlighted?
  const sortedClaimList = state.claimList.sort((a, b) => {
    if (a.InvoiceGuid === state.claim.InvoiceGuid) return -1;
    if (b.InvoiceGuid === state.claim.InvoiceGuid) return 1;
    return 0;
  });

  return (
    <>
      {state.loading && <CircularProgress />}
      <DataTable
        dataKey="InvoiceGuid"
        value={sortedClaimList}
        emptyMessage={state.emptyMessage || t('No_records_found')}
        selectionMode="single"
        selection={state.selectedItem}
        onSelectionChange={onSelectionChange}
        header={<TableHeader />}
        lazy
        // stripedRows
        removableSort
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}
        paginator
        pageLinkSize={3}
        first={state.first}
        totalRecords={state.totalRecords}
        rows={defaultRequestParams.pageSize}
        // paginatorLeft={<PaginatorLeft />}
        // paginatorRight={<PaginatorRight />}
        currentPageReportTemplate={`${state.first} to ${last} of ${state.totalRecords}`}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        scrollable
        scrollHeight="flex"
        tableStyle={tableStyle}
        style={{ height: `auto`, maxHeight: 'calc(100vh - 180px)' }}
        onPage={onPage}
        onRowSelect={(e) => dispatch(setClaimInfo(e.data))} // set claim info to highlight it in the invoices data table
      >
        <Column
          sortable
          field="ServiceDate"
          header={t('Date_of_service')}
          style={{ minWidth: '150px' }}
          body={(rowData) => <ServiceDateLayout rowData={rowData} />}
        />

        <Column sortable field="Status" header={t('Status')} style={{ minWidth: '150px' }} body={(rowData) => <StatusLayout rowData={rowData} />} />

        <Column
          sortable
          field="FeeCode"
          header={t('Fee_item')}
          style={{ minWidth: '110px' }}
          body={(rowData) => <FeeCodeLayout rowData={rowData} />}
        />

        <Column field="Units" header={t('Units')} style={{ minWidth: '70px' }} />

        <Column
          field="Time"
          header={t('Time')}
          style={{ minWidth: '90px', maxWidth: '120px' }}
          body={(rowData) => <TimeLayout rowData={rowData} />}
        />

        <Column field="Time" header={t('Span')} style={{ minWidth: '90px', maxWidth: '90px' }} body={(rowData) => <SpanLayout rowData={rowData} />} />

        <Column
          sortable
          field="ICD9Code"
          header={t('Dx')}
          style={{ minWidth: '100px', maxWidth: '150px' }}
          body={(rowData) => <DxCodeLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="FeeTotal"
          header={t('Billed')}
          style={{ minWidth: '100px', maxWidth: '150px' }}
          body={(rowData) => <BilledLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="FeePaid"
          header={t('Paid')}
          style={{ minWidth: '100px', maxWidth: '150px' }}
          body={(rowData) => <PaidLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="RefNumber"
          header={t('Referral')}
          style={{ minWidth: '100px', maxWidth: '150px' }}
          body={(rowData) => <ReferralCodeLayout rowData={rowData} />}
        />

        <Column
          field="SubmissionCode"
          header="Submission"
          style={{ minWidth: '100px' }}
          body={(rowData) => <SubmissionCodeLayout rowData={rowData} />}
        />

        <Column field="ResponseCode" header="Rejection" style={{ minWidth: '100px' }} body={(rowData) => <RejectionCodeLayout rowData={rowData} />} />

        <Column
          field="Comment"
          header={t('Comment')}
          style={{ minWidth: '150px', maxWidth: '350px' }}
          body={(rowData) => <CommentLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="RecordNo"
          header={t('Record Id')}
          style={{ minWidth: '110px' }}
          body={(rowData) => <SequensNumberLayout rowData={rowData} />}
        />

        {showArchiveColumn && <Column field="rowActions" style={{ minWidth: '80px' }} body={(rowData) => <ArchiveButton rowData={rowData} />} />}
      </DataTable>
    </>
  );
};

export default SimilarClaimsTable;
