import Base64 from 'base-64';
import moment from 'moment';

export const provinces = [
  { value: 'AB', label: 'AB' },
  { value: 'BC', label: 'BC' },
  { value: 'SK', label: 'SK' },
  { value: 'MB', label: 'MB' },
  { value: 'ON', label: 'ON' },
  { value: 'NB', label: 'NB' },
  { value: 'NS', label: 'NS' },
  { value: 'PE', label: 'PE' },
  { value: 'NF', label: 'NF' },
  { value: 'NT', label: 'NT' },
  { value: 'YT', label: 'YT' },
  { value: 'NU', label: 'NU' }
];

export const monthes = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export function getItemFromArray(arr, id, field) {
  let found = null;
  if (id === undefined) return found;
  if (arr === undefined || arr == null) return found;
  if (arr.length === undefined) arr = [arr];
  arr.forEach((element) => {
    if (element !== undefined) {
      if (element?.id === id) {
        found = element;
      }
      if (element?.value !== undefined && element.value !== null) {
        if (typeof element.value === 'string' && typeof id === 'string' && element.value.toLowerCase() === id.toLowerCase()) {
          found = element;
        }
        if (typeof element?.value !== 'string' && element.value === id) {
          found = element;
        }
      }
      if (element?.CodeGuid !== undefined) {
        if (element.CodeGuid.toLowerCase() === id.toLowerCase()) {
          found = element;
        }
      }
      if (element && field !== undefined && element[field] !== undefined) {
        let item_id = element[field];
        if (typeof id === 'number' && typeof item_id === 'string' && isNumeric(item_id)) {
          item_id = parseInt(item_id, 10);
        }
        if (typeof item_id === 'number') {
          if (item_id === id) {
            found = element;
          }
        } else {
          if (item_id.toLowerCase() === id.toLowerCase()) {
            found = element;
          }
        }
      }
    }
  });
  return found;
}

export function arrayUnique(array) {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
}

export function getChipValue(value) {
  const pairs = value && value.split('|');
  return pairs && pairs[1];
}

export function getChipValueCSV(arr) {
  const lines = arr.split(',');
  return lines.map((x) => getChipValue(x));
}

export function getPatientArrayCSV(arr) {
  const lines = arr.split(',');
  return lines.map((x) => {
    const parts = x.split('|');
    if (parts.length > 1) {
      return { PatientGuid: parts[0], value: parts[1] };
    }
  });
}

export function dropdownUnique(array) {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i].label === a[j].label) a.splice(j--, 1);
    }
  }
  return a;
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function encoded4API(text) {
  let reply = Base64.encode(text);
  if (reply.endsWith('=') === false) reply += '===';
  return reply;
}

export function comparable(o) {
  return typeof o != 'object' || !o
    ? o
    : Object.keys(o)
        .sort()
        .reduce((c, key) => ((c[key] = comparable(o[key])), c), {});
}

export function shallowEqual(a, b) {
  return JSON.stringify(comparable(a)) === JSON.stringify(comparable(b));
}

// trimmed version from https://levelup.gitconnected.com/handling-complex-form-state-using-react-hooks-76ee7bc937
export function enhancedReducer(state, updateArg) {
  // check if the type of update argument is a callback function
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) };
  }
  // if the type of update argument is an object
  if (updateArg.constructor === Object) {
    return { ...state, ...updateArg };
  }
}

export function checkMandatoryFields(entityType, entity, requiredFields) {
  const patient = ['FirstName', 'LastName', 'Sex', 'BirthDay'];
  let list = [];
  let reply = {};

  switch (entityType) {
    case 'patient':
      list = patient;
      break;
    case 'claim':
      list = requiredFields;
      break;
    default:
      break;
  }

  Object.keys(entity).forEach((x) => {
    const index = list.indexOf(x);
    if (index > -1) {
      list.splice(index, 1);
      if (!!entity[x] === false || entity[x].length === 0) {
        return (reply = {
          ...reply,
          [x]: `Missing value in ${x} field`
        });
      }
    }
  });

  // list.forEach(x => {
  //   reply.push(`Missing ${x} field`);
  // });

  return reply;
}

export function formatMoney(num) {
  //return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  let value = typeof num === 'string' ? num.replace('$', '') : num;
  const suspect = parseFloat(value);
  if (isNaN(suspect)) value = 0;
  return (
    '$' +
    value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  );
}

export function formatPHN_old(num) {
  let reply = '';
  if (num && isNumeric(num[0])) {
    const subject = num.replace(/ /g, '');
    const total = subject.length - 1;
    for (let i = total; i >= 0; i--) {
      if ((total - i) % 3 === 0 && i !== 0) reply += ' ';
      reply += subject[i];
    }
    return reply.split('').reverse().join('').slice(1, -1);
  }
  return reply;
}

export function isInViewport(elem) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function findDiff(str1, str2) {
  let diff = '';
  str2.split('').forEach(function (val, i) {
    if (val !== str1.charAt(i)) diff += val;
  });
  return diff;
}

function isValidYear(year) {
  return !!(isNumeric(year) && year > 1899 && year < 2223);
}

function isValidMonth(month) {
  return !!(isNumeric(month) && month > 0 && month < 13);
}

function isValidMonthName(month) {
  return isNumeric(month) === false && monthes.indexOf(month) > -1;
}

export function isValidPartialDate(value) {
  const suspect = value.replace(/\//g, '').replace(/-/g, '').replace(/ /g, '').replace(/M/g, '').replace(/D/g, '');
  const len = suspect.trim().length;
  let year = '';
  let month = '';
  switch (len) {
    case 0: // year ("")
      return true;
    case 4: // year (1994)
      if (isNaN(parseInt(suspect, 10)) === false && isValidYear(suspect)) return true;
      break;
    case 5: // year and month num (199004)
      if (isNaN(parseInt(suspect, 10)) === false) {
        year = suspect.substring(0, 4);
        month = suspect.substring(4, 5);
        if (isValidYear(year) && '01'.indexOf(month) > -1) return true;
      }
      break;
    case 6: // year and month num (199004)
      if (isNaN(parseInt(suspect, 10)) === false) {
        year = suspect.substring(0, 4);
        month = suspect.substring(4, 6);
        if (isValidYear(year) && isValidMonth(month)) return true;
      }
      break;
    case 7: // year and month name (1994Jan) or 1994051
      if (isNaN(parseInt(suspect, 10)) === false) {
        year = suspect.substring(0, 4);
        month = suspect.substring(4, 6);
        const day = suspect.substring(6, 7);
        if (isValidYear(year) && isValidMonth(month) && '0123'.indexOf(day) > -1) return true;
      }
      year = suspect.substring(0, 4);
      month = suspect.substring(4, 7);
      if (isValidYear(year) && isValidMonthName(month)) return true;
      break;
    case 8: // year, month, day (19940105)
      return moment(value, 'YYYY/MM/DD', true).isValid();
    default:
      break;
  }
  return false;
}

export const filterByYear = (remPeriod, remList) => {
  const today = new Date();
  const thisYear = today.getFullYear();
  const previousYear = thisYear - 1;
  const periodYear = remPeriod === 1 || remPeriod === '1' ? thisYear : previousYear;

  const filtered = remList.filter((x) => {
    const suspect = new Date(x.label);
    if (suspect.getFullYear() === periodYear) return x;
  });
  const reply = [{ label: 'All', value: '-2' }];
  return reply.concat(filtered);
};

export const isSameString = (a, b) => {
  if ((a + '').toLowerCase() > (b + '').toLowerCase()) return false;
  if ((a + '').toLowerCase() < (b + '').toLowerCase()) return false;
  return true;
};

export const formatName4Event = (event) => {
  if (event.PatientFirstName && event.PatientLastName) {
    return `${event.PatientFirstName.substring(0, 1)}. ${event.PatientLastName}`;
  }
  return null;
};
