import React from 'react';
import { Tooltip } from 'primereact/tooltip';

const RejectionCodeLayout = ({ rowData }) => {
  return (
    <>
      <Tooltip target=".rejectionCodeTooltip" position="top" className="max-w-30rem">
        {rowData.MSPCodes}
      </Tooltip>
      <span className="rejectionCodeTooltip">{rowData.ResponseCode}</span>
    </>
  );
};

export default RejectionCodeLayout;
