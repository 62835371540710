/**
 * This reducer provides formatted data..
 * PatientList supports two modes: appening page of results (for autisuggestion) or overwriting (for grid)
 */

import actionTypes from '../actions/claims.action.types';
import { formatMoney } from '../../../Helper';
import { getAge } from '../../utils/getAge';
import { defaultPageSize, claimsDefaultSortBy, claimsFiltersWithPeriod } from '../../config/defaultValuesConfig';
import { getFromLocalStorage } from '../../utils/getFromLocalStorage';
import { localStorageKeys } from '../../config/localStorageKeysConfig';
import { isEmpty } from 'lodash';

export const claimInitialState = {
  active_item: 0,
  active_offset: 0,
  claim_list: [],
  complete_list: [],
  selectedPatients: [],
  counters: [],
  catalogCategories: getFromLocalStorage(localStorageKeys.invoicesCatalogCategories, {}),
  catalogMostRecentCodes: {},
  claimsData: {},
  selectedCodes: {},
  statusOptions: {},
  claimRecord: {},
  privateRecord: {},
  wsbcRecord: {},
  teleplanInvoice: {},
  claim_details: {},
  paymentDetails: {},
  dataTableExpandedRows: {},
  claim_index: 0,
  totalPages: 0,
  totalBilled: 0,
  totalPaid: 0,
  totalDue: 0,
  first: 0,
  totalRecords: 0,
  page: 0,
  pageSize: getFromLocalStorage(localStorageKeys.invoicesPageSize, defaultPageSize),
  // pageSize: defaultPageSize,
  filters: claimsFiltersWithPeriod,
  query: '',
  searchFilter: '',
  patientPickerQueryValue: '',
  sortby: claimsDefaultSortBy.sortOrder,
  resetClaimForm: false,
  autocomplete: false,
  gettingWSBCReport: false,
  deletingClaim: false,
  isSavingClaims: false,
  gettingInvoicesCatalogCategories: false,
  gettingrRecentCodesForCurrentUser: false,
  isFetchingClaims: false,
  isFetchingClaimHistory: false,
  isFetchingDebitRequest: false,
  shouldResetNewInvoiceForm: true,
  createClaimPanelCollapsed: true,
  gettingClaimInfo: false,
  isFetchingCounters: false,
  isFetchingInvoicesMostRecentCodes: false,
  isFetchingGeneratedInvoices: false,
  filterByUserForInvoicesViewPanel: false,
  isSubmittingAllClaims: false,
  creatingPrivateRecord: false,
  isArchiving: false,
  savingReceivePayment: false,
  deletingPayment: false,
  isGettingPatientsMostReacentClaim: false,
  showMissingPatientDataDialog: false,
  showTotalOutstandingDialog: { showDialog: false, type: '' },
  gettingInsurancePolicy: false
};

const formatList = (list) => {
  return list.map((x, index) => {
    x.index = index;
    x.ServiceDate = (x.ServiceDate || '').replace('T00:00:00', '');
    x.InvoiceDate = (x.InvoiceDate || '').replace('T00:00:00', '');
    x.InjuryDay = (x.InjuryDay || '').replace('T00:00:00', '');
    x.Age = getAge(x.BirthDay);
    return x;
  });
};

export default function claimsReducer(state = claimInitialState, action) {
  const { results } = action;
  const onePage = results ? results.data : {};
  let newList = [];

  switch (action.type) {
    case actionTypes.FETCH_CLAIMS_SUCCESS:
      newList = onePage.claimList;
      return {
        ...state,
        error: null,
        claim_list: formatList(newList),
        statusList: results.statusList,
        page: results.page,
        active_item: results.active_item,
        active_offset: 0,
        first: (results.page - 1) * results.pageSize + 1,
        totalPages: results.totalPages,
        totalRecords: results.totalRecords,
        totalRecordsAlter: results.totalRecordsAlter,
        totalBilled: formatMoney(results.totalBilled),
        totalPaid: formatMoney(results.totalPaid),
        totalDue: formatMoney(results.totalDue),
        pageSize: results.pageSize,
        query: results.query,
        filters: JSON.parse(results.query),
        sortby: results.sortby,
        PatientGuid: results.PatientGuid
      };

    case actionTypes.FETCH_CLAIMS_LIST_SUCCESS:
      newList = [];
      if (state.query !== results.query) {
        newList = onePage.claimList;
      } else {
        newList = [...state.complete_list, ...onePage.claimList];
      }
      newList = newList.map((item, index) => {
        item.index = index;
        return item;
      });
      return {
        ...state,
        complete_list: formatList(newList),
        page: results.page,
        active_item: results.active_item,
        active_offset: 0,
        first: (results.page - 1) * results.pageSize + 1,
        totalPages: results.totalPages,
        totalRecords: results.totalRecords,
        totalRecordsAlter: results.totalRecordsAlter,
        totalBilled: results.totalBilled,
        totalPaid: results.totalPaid,
        totalDue: results.totalDue,
        pageSize: results.pageSize,
        query: results.query
      };

    case actionTypes.FETCH_CLAIMS_LIST_FAILURE:
      return {
        ...state,
        complete_list: []
      };

    case actionTypes.SET_CLAIM_LIST:
      return {
        ...state,
        claim_list: results
      };

    case actionTypes.SET_COMPLETE_LIST:
      return {
        ...state,
        complete_list: results
      };

    case actionTypes.FETCH_CLAIMS_COUNTERS_SUCCESS:
      return {
        ...state,
        counters: results
      };

    case actionTypes.FETCH_CLAIM_DETAILS_SUCCESS:
      return {
        ...state,
        claim_details: results
      };

    case actionTypes.SET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: results
      };

    case actionTypes.CLAIM_VALIDATION_SUCCESS:
      return {
        ...state,
        isValid: true
      };

    case actionTypes.CLAIM_VALIDATION_FAILURE:
      return {
        ...state,
        isValid: false
      };

    case actionTypes.PREPARE_CLAIMS_SUCCESS:
      return {
        ...state,
        generated_list: results.data.claimList
      };

    case actionTypes.CLAIM_FILTERS:
      return {
        ...state,
        filters: results
      };

    case actionTypes.CLAIM_STATUS_OPTIONS:
      return {
        ...state,
        statusOptions: results
      };

    case actionTypes.IS_FETCHING_CLAIMS:
      return {
        ...state,
        error: null,
        isFetchingClaims: results
      };

    case actionTypes.IS_SAVING_CLAIMS:
      return {
        ...state,
        isSavingClaims: results
      };

    case actionTypes.IS_FETCHING_CLAIM_HISTORY:
      return {
        ...state,
        isFetchingClaimHistory: results
      };

    case actionTypes.CLAIMS_SELECTED_CODES:
      return {
        ...state,
        selectedCodes: results
      };

    case actionTypes.SET_CLAIM_INDEX:
      return {
        ...state,
        claim_index: results
      };

    case actionTypes.SET_CLAIM_DIR:
      const new_offset = state.active_offset + results;
      return {
        ...state,
        active_offset: new_offset
      };

    case actionTypes.SET_CLAIM_RECORD:
      return {
        ...state,
        claimRecord: results
      };

    case actionTypes.UPDATE_CLAIM_RECORD:
      const updatedClaimRecord = isEmpty(results) ? {} : { ...state.claimRecord, ...results };
      return {
        ...state,
        claimRecord: updatedClaimRecord
      };

    case actionTypes.SET_PRIVATE_RECORD:
      return {
        ...state,
        privateRecord: results
      };

    case actionTypes.UPDATE_PRIVATE_RECORD:
      const privateRecord = isEmpty(results) ? {} : { ...state.privateRecord, ...results };
      return {
        ...state,
        privateRecord
      };

    case actionTypes.SET_PATIENTS_FOR_CLAIM_DETAILS:
      return {
        ...state,
        selectedPatients: results
      };

    case actionTypes.SHOULD_RESET_CLAIMS_FORM:
      return {
        ...state,
        shouldResetNewInvoiceForm: results
      };

    case actionTypes.IS_FETCHING_GENERATED_INVOICES:
      return {
        ...state,
        isFetchingGeneratedInvoices: results
      };

    case actionTypes.SET_CLAIMS_DEFAULT_TABLE_VALUES:
      return {
        ...state,
        page: 0,
        // pageSize: defaultPageSize,
        pageSize: getFromLocalStorage(localStorageKeys.invoicesPageSize, defaultPageSize),
        sortby: claimsDefaultSortBy.sortOrder,
        filters: claimsFiltersWithPeriod,
        complete_list: []
      };

    case actionTypes.CREATE_CLAIM_PANEL_COLLAPSED:
      return {
        ...state,
        createClaimPanelCollapsed: results
      };

    case actionTypes.SET_CLAIMS_DATA:
      return {
        ...state,
        claimsData: results
      };

    case actionTypes.RESET_WSBC_CLAIM_DATA:
      const commonState = {
        ...state.claimRecord,
        InjuryDay: '',
        NatureOfInjury: [],
        NOIText: [],
        AreaOfInjury: [],
        AOIText: [],
        AnatomicCode: results.type === 'WSBC' ? 'N' : ''
        // ICD9Code: [], // [KS] CMO-1305 - Dx clears out when editing WSBC claim and changing payor from WSBC to MSP
        // ICD9Text: [] // [KS] CMO-1305 - Dx clears out when editing WSBC claim and changing payor from WSBC to MSP
      };

      const claimRecord =
        results.claimGuid && results.resetClaimNumber
          ? {
              ...commonState,
              ClaimGuid: results.claimGuid,
              ClaimNumber: 'N/A'
            }
          : !results.claimGuid && results.resetClaimNumber
          ? {
              ...commonState,
              ClaimNumber: 'N/A'
            }
          : {
              ...commonState,
              ClaimGuid: results.claimGuid
            };

      return {
        ...state,
        claimRecord
      };

    case actionTypes.SET_VALUES_FOR_WSBC:
      return {
        ...state,
        claimRecord: {
          ...state.claimRecord,
          ClaimGuid: results.ClaimGuid,
          ClaimNumber: results.ClaimNumber,
          InjuryDay: results.InjuryDay,
          NatureOfInjury: results.NatureOfInjury,
          NOIText: results.NOIText,
          AreaOfInjury: results.AreaOfInjury,
          AOIText: results.AOIText,
          AnatomicCode: results.AnatomicCode,
          ICD9Code: results.ICD9Code,
          ICD9Text: results.ICD9Text
        }
      };

    case actionTypes.SET_VALUES_FOR_ICBC:
      return {
        ...state,
        claimRecord: {
          ...state.claimRecord,
          ClaimGuid: results.ClaimGuid,
          ClaimNumber: results.ClaimNumber,
          ICD9Code: results.ICD9Code,
          ICD9Text: results.ICD9Text
        }
      };

    case actionTypes.RESET_ICBC_CLAIM_DATA:
      return {
        ...state,
        claimRecord: {
          ...state.claimRecord,
          ClaimGuid: results,
          ICD9Code: '',
          ICD9Text: []
        }
      };

    case actionTypes.FILTER_BY_PATIENT_FOR_CLAIMS:
      return {
        ...state,
        filterByUserForInvoicesViewPanel: results
      };

    case actionTypes.IS_FETCHING_COUNTERS:
      return {
        ...state,
        isFetchingCounters: results
      };

    case actionTypes.IS_FETCHING_INVICES_MOST_RECENT_CODES:
      return {
        ...state,
        isFetchingInvoicesMostRecentCodes: results
      };

    case actionTypes.GET_INVOICES_CATALOG_MOST_RECENT_CODES:
      return {
        ...state,
        catalogMostRecentCodes: results
      };

    case actionTypes.UPDATE_INVOICES_CATALOG_MOST_RECENT_CODES:
      if (results.codeType) {
        return {
          ...state,
          catalogMostRecentCodes: {
            ...state.catalogMostRecentCodes,
            [results.codeType]: results.data
          }
        };
      }
      return {
        ...state,
        catalogMostRecentCodes: results.data
      };

    case actionTypes.GET_ALL_CATALOG_CATEGORIES:
      return {
        ...state,
        catalogCategories: results
      };

    case actionTypes.SET_ACTIVE_TAB_INDEX_FOR_INVOICES_CATALOG:
      return {
        ...state,
        catalogCategories: {
          ...state.catalogCategories,
          [results.type]: {
            ...state.catalogCategories[results.type],
            activeTabIndex: results.index
          }
        }
      };

    case actionTypes.IS_FETCHING_DEBID_REQUEST:
      return {
        ...state,
        isFetchingDebitRequest: results
      };

    case actionTypes.IS_SUBMITTING_ALL_CLAIMS:
      return {
        ...state,
        isSubmittingAllClaims: results
      };

    // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
    case actionTypes.SET_EXPANDED_ROWS:
      return {
        ...state,
        dataTableExpandedRows: results
      };

    case actionTypes.CREATING_PRIVATE_RECORD:
      return {
        ...state,
        creatingPrivateRecord: results
      };

    case actionTypes.IS_ARCHIVING:
      return {
        ...state,
        isArchiving: results
      };

    case actionTypes.UPDATE_CLAIMS_STATE:
      return {
        ...state,
        ...results
      };

    case actionTypes.RESET_CLAIM_FORM:
      return {
        ...state,
        resetClaimForm: results
      };

    case actionTypes.SAVING_RECEIVE_PAYMENT:
      return {
        ...state,
        savingReceivePayment: results
      };

    case actionTypes.DELETING_PAYMENT:
      return {
        ...state,
        deletingPayment: results
      };

    case actionTypes.GET_PATIENTS_MOST_REASENT_CLAIM:
      return {
        ...state,
        isGettingPatientsMostReacentClaim: results
      };

    case actionTypes.GETTING_CLAIM_INFO:
      return {
        ...state,
        gettingClaimInfo: results
      };

    case actionTypes.SET_PATIENT_PICKER_QUERY_VALUE:
      return {
        ...state,
        patientPickerQueryValue: results
      };

    case actionTypes.UPDATE_WSBC_RECORD:
      return {
        ...state,
        wsbcRecord: results
      };

    case actionTypes.UPDATE_TELEPLAN_INVOICE:
      return {
        ...state,
        teleplanInvoice: results
      };

    case actionTypes.GETTING_WSBC_REPORT:
      return {
        ...state,
        gettingWSBCReport: results
      };

    case actionTypes.DELETING_CLAIM:
      return {
        ...state,
        deletingClaim: results
      };

    case actionTypes.SHOW_MISSING_PATIENT_DATA_DIALOG:
      return {
        ...state,
        showMissingPatientDataDialog: results
      };

    case actionTypes.SHOW_TOTAL_OUTSTANDING_DIALOG:
      return {
        ...state,
        showTotalOutstandingDialog: results
      };

    case actionTypes.SENDING_BY_EMAIL:
      return {
        ...state,
        sendingByEmail: results
      };

    case actionTypes.GETTING_INSURANCE_POLISY:
      return {
        ...state,
        gettingInsurancePolicy: results
      };

    case actionTypes.GETTING_RECENT_CODES_FOR_CURRENT_USER:
      return {
        ...state,
        gettingrRecentCodesForCurrentUser: results
      };

    case actionTypes.GETTING_INVOICES_CATALOG_CATEGORIES:
      return {
        ...state,
        gettingInvoicesCatalogCategories: results
      };

    default:
      return state;
  }
}
