import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CommonInputField from '../../../common/components/CommonInputField';
import PHNInput from '../commonElements/PHNInput';
import CheckMSPButton from '../commonElements/CheckMSPButton';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { t } from '../../../../service/localization/i18n';
import moment from 'moment';
import cx from 'classnames';
import { duplicatePatientCheck, getEligibility, setEligibility } from '../../actions/patients.action.creators';
import { getEveryFirstLetterOfString } from '../../../utils/getEveryFirstLetterOfString';
import { checkIfPatientDemographicsAreDifferent } from './helpers/checkIfPatientDemographicsAreDifferent';
import MismatchDialog from '../commonElements/MismatchDialog';
import { isString } from 'lodash';
import { Button } from 'primereact/button';
import { elementIDs } from '../../../config/elementIDsConfig';
import { commonInputs } from '../../../config/commonInputsConfig';
import { integersAndLettersOnly } from '../../../regex/regex';
import ReferralInput from './ReferralInput';
import { InputSwitch } from 'primereact/inputswitch';
import { DatePicker } from '../../../../components/Inputs';

const Demographics = ({
  isNew,
  phnRef,
  formik,
  isPHNValid,
  isDateValid,
  setIsDateValid,
  setIsPHNValid,
  isFormFieldValid,
  isPHNDuplicate,
  setIsPHNDuplicate,
  setShowPHNDuplicateDialog,
  getFormErrorMessage,
  checkPatientDuplicate,
  resetDemographics,
  PHNRequired = false,
  checkMSPSmallGap = false,
  checkDuplicateOnMSPClick = false,
  inputFieldClassName,
  setIsPHNFocused,
  duplicateCheckResponseCallback,
  checkDuplicateWithDOBandLastName,
  disabledPHN,
  genderDir = 'column' // avalible options "column" and "row"
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useSelector((state) => state.core.window);
  const { eligibility, checkingPatientDuplicates, isFetchingPHNValidation, isFetchingPatientEligibility } = useSelector((state) => state.patients);

  const [showMismatchDialog, setShowMismatchDialog] = useState(false);
  const [showNameMismatch, setShowNameMismatch] = useState(false);
  const [differentPatientsDemographics, setDifferentPatientsDemographics] = useState(false);
  const [isDeceasedDateFuture, setIsDeceasedDateFuture] = useState(false);

  // === CMO-1989 - Edit patient's profile->Edit Last Name->cursor jumps to the end when adding a word in front of the current last name ===/
  const lastNameInputRef = useRef(null);
  const firstNamtInputRef = useRef(null);
  const midNameInputRef = useRef(null);

  const [lastNameCursor, setLastNameCursor] = useState(null);
  const [firstNamtCursor, setFirstNameCursor] = useState(null);
  const [midNameCursor, setMidNameCursor] = useState(null);

  useEffect(() => {
    const input = lastNameInputRef.current;
    if (input) input.setSelectionRange(lastNameCursor, lastNameCursor);
  }, [lastNameInputRef, lastNameCursor, formik.values.LastName]);

  useEffect(() => {
    const input = firstNamtInputRef.current;
    if (input && input.setSelectionRange) input.setSelectionRange(firstNamtCursor, firstNamtCursor);
  }, [firstNamtInputRef, firstNamtCursor, formik.values.FirstNam]);

  useEffect(() => {
    const input = midNameInputRef.current;
    if (input && input.setSelectionRange) input.setSelectionRange(midNameCursor, midNameCursor);
  }, [midNameInputRef, midNameCursor, formik.values.midNameCursor]);

  useEffect(() => {
    const input = phnRef.current;
    if (isNew && isMobile && input && input.setSelectionRange) input.setSelectionRange(0, 0);
  }, [isNew, isMobile]);
  // ===================================================================== //

  const onBlurDuplicateCheck = (value, field) => {
    if (checkDuplicateWithDOBandLastName) checkDuplicateWithDOBandLastName(value, field);
  };

  const checkEligibilityOnly = () => {
    let params = {
      PHN: formik.values.PHN,
      DOB: formik.values.BirthDay
    };

    if (!isNew) params = { ...params, PatientGuid: formik.values.PatientGuid };

    dispatch(getEligibility(params, (responseData) => dispatch(setEligibility(responseData.Parsed))));
  };

  const checkPHN = () => {
    let params = {
      PHN: formik.values.PHN,
      DOB: formik.values.BirthDay
    };

    if (!isNew) params = { ...params, PatientGuid: formik.values.PatientGuid };

    dispatch(getEligibility(params, (responseData) => getEligibilityCallback(responseData)));
  };

  const getEligibilityCallback = (data) => {
    dispatch(setEligibility(data.Parsed));

    // [KS] CMO-1380 - Edit Demographics->Check Eligibility- Gender is not updated
    if (!isNew && !formik.values.Sex) {
      formik.setValues({
        ...formik.values,
        Sex: getEveryFirstLetterOfString(data?.Parsed?.GENDER)
      });
    }

    // [KS] CMO-1235 - Eligibility report for a Create New patient does not fill in name and gender
    if (formik.values.Sex || formik.values.FirstName || formik.values.LastName || formik.values.MidName) {
      const isDifferentDemographics = checkIfPatientDemographicsAreDifferent({
        eligibility: data.Parsed,
        formikValues: formik.values
      });
      setDifferentPatientsDemographics(isDifferentDemographics);
      setShowMismatchDialog(true);
    } else {
      updateFormikState(data.Parsed);
      differentPatientsDemographics && setDifferentPatientsDemographics(false);
    }
  };

  const updateFormikState = (eligibilityData) => {
    const firstName = eligibilityData.FIRST_NAME || '';
    const lastName = eligibilityData.LAST_NAME || '';
    const midName = eligibilityData.MID_NAME || '';
    const gender = getEveryFirstLetterOfString(eligibilityData.GENDER);

    formik.setValues({
      ...formik.values,
      FirstName: firstName,
      LastName: lastName,
      MidName: midName,
      Sex: gender,
      FullName: `${lastName}, ${firstName} ${midName}`,
      Summary: `${lastName}, ${firstName} ${midName} (${formik.values.Age}${gender})`
    });

    if (checkDuplicateOnMSPClick) {
      const _patientDetails = {
        DCNGuid: formik.values.DCNGuid,
        DOB: formik.values.BirthDay,
        LastName: lastName || ''
      };

      dispatch(
        duplicatePatientCheck(_patientDetails, (responseData) => {
          if (responseData?.length) duplicateCheckResponseCallback(responseData);
        })
      );
    }
  };

  const onNameChange = (e, fieldName) => {
    switch (fieldName) {
      case 'LastName':
        setLastNameCursor(e.target.selectionStart);
        break;
      case 'FirstName':
        setFirstNameCursor(e.target.selectionStart);
        break;
      case 'MidName':
        setMidNameCursor(e.target.selectionStart);
        break;

      default:
        break;
    }

    formik.handleChange(e);
    formik.setFieldValue(fieldName, e.target.value.toUpperCase());
  };

  // [KS] CMO-641 - Show error message if the DOB is invalid on New Patient screen
  const dobOnChange = (e) => {
    const value = e.target.value;
    value && setIsDateValid(true);
    formik.handleChange(e);
  };

  // [KS] CMO-641 - Show error message if the DOB is invalid on New Patient screen
  const dobOnBlur = (e) => {
    let value = e.target.value;
    if (value.length === 8 && value.indexOf('/') > -1) {
      const thisyear = new Date().getFullYear() - 2000;
      const monthday = value.substring(0, 6);
      const yearonly = value.substring(6);
      let yearint = parseInt(yearonly, 10);
      if (yearint > thisyear) {
        yearint = 1900 + yearint;
      } else {
        yearint = 2000 + yearint;
      }
      value = monthday + yearint.toString();
      formik.values.BirthDay = value;
    }
    const isValid = moment(value, 'MM/DD/YYYY', true).isValid();
    onBlurDuplicateCheck(value, 'BirthDay');
    value?.length > 0 && !isValid ? setIsDateValid(false) : setIsDateValid(true);
  };

  const birthDayNumbersOnly =
    isString(formik?.values?.BirthDay) &&
    formik?.values?.BirthDay?.replaceAll('M', '')?.replaceAll('D', '')?.replaceAll('Y', '')?.replaceAll('/', '');
  const checkMSPDisabled =
    !formik.values.PHN || formik.values.PHN === 'N/A' || !formik.values.BirthDay || !isDateValid || !isPHNValid || birthDayNumbersOnly?.length < 8;

  const checkMSPButton = (
    <CommonInputField className={cx(inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1')} style={{ height: '50px' }}>
      <CheckMSPButton
        id={elementIDs.checkMSPNameAndEligibility}
        label={t('Check_MSP_Spelling_and_Eligibility')}
        tooltip={t('Enter_PHN_and_DOB')}
        disabled={checkMSPDisabled || isFetchingPHNValidation || checkingPatientDuplicates || formik.values.PHNProvince !== 'BC'}
        loading={isFetchingPatientEligibility}
        onClick={checkPHN}
      />
    </CommonInputField>
  );

  // const checkEligibilityOnlyButton = (
  //   <CommonInputField className="col-6 py-0 px-2 mb-1 flex align-items-center" style={(isMobile || checkMSPSmallGap) && { height: '70px' }}>
  //     <Button
  //       id={elementIDs.checkEligibilityOnly}
  //       className="p-button-text p-button-content"
  //       label={t('Check_Eligibility_only')}
  //       type="button"
  //       tooltip={t('Enter_PHN_and_DOB')}
  //       tooltipOptions={{ position: 'top' }}
  //       disabled={checkMSPDisabled || isFetchingPHNValidation || checkingPatientDuplicates || formik.values.PHNProvince !== 'BC'}
  //       loading={isFetchingPatientEligibility}
  //       autoFocus={false}
  //       onClick={checkEligibilityOnly}
  //     />
  //   </CommonInputField>
  // );

  const nameMismatch = (
    <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 mb-1'} style={{ height: '50px' }}>
      <Button
        id={elementIDs.nameMismatch}
        className="p-button-text"
        type="button"
        label={t('Name_Mismatch')}
        formik={formik}
        isMobile={isMobile}
        onClick={() => setShowNameMismatch(true)}
      />
    </CommonInputField>
  );

  return (
    <>
      <PHNInput
        inputWrapClassName={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 mb-1 px-2'}
        ref={phnRef}
        formik={formik}
        autoFocus={isNew}
        noGap
        isNew={isNew}
        disabledPHN={disabledPHN}
        required={PHNRequired}
        isPHNValid={isPHNValid}
        setIsPHNValid={setIsPHNValid}
        isFormFieldValid={isFormFieldValid}
        getFormErrorMessage={getFormErrorMessage}
        checkPatientDuplicate={checkPatientDuplicate}
        isPHNDuplicate={isPHNDuplicate}
        setIsPHNFocused={setIsPHNFocused}
        resetDemographics={resetDemographics}
        setIsPHNDuplicate={setIsPHNDuplicate}
        setShowPHNDuplicateDialog={setShowPHNDuplicateDialog}
      />

      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        required
        label={`${t('Day_of_birthday.2')} (MM/DD/YYYY)`}
        errorMessage={getFormErrorMessage('BirthDay') || (!isDateValid && t('Invalid_date_format'))}
      >
        <InputMask
          className={cx({
            'p-invalid': isFormFieldValid('BirthDay') || !isDateValid
          })}
          id="BirthDay"
          name="BirthDay"
          placeholder={t('MM_DD_YYYY')}
          mask="99/99/99?99"
          value={formik.values.BirthDay}
          slotChar={t('MM_DD_YYYY')}
          onChange={dobOnChange}
          onBlur={dobOnBlur}
        />
      </CommonInputField>

      {checkMSPButton}
      {isNew ? (
        nameMismatch
      ) : (
        <CommonInputField
          className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 mb-1'}
          style={{ height: '50px' }}
        ></CommonInputField>
      )}

      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        required
        label={t('Last_Name')}
        errorMessage={getFormErrorMessage('LastName')}
      >
        <InputText
          ref={lastNameInputRef}
          className={cx({
            'p-invalid': isFormFieldValid('LastName')
          })}
          id="LastName"
          name="LastName"
          value={formik.values.LastName}
          onChange={(e) => onNameChange(e, 'LastName')}
          onBlur={(e) => onBlurDuplicateCheck(e.target.value, 'LastName')}
        />
      </CommonInputField>

      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        required
        label={t('First_Name')}
        errorMessage={getFormErrorMessage('FirstName')}
      >
        <InputText
          ref={firstNamtInputRef}
          className={cx({
            'p-invalid': isFormFieldValid('FirstName')
          })}
          id="FirstName"
          name="FirstName"
          value={formik.values.FirstName}
          onChange={(e) => onNameChange(e, 'FirstName')}
        />
      </CommonInputField>

      <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'} label={`${t('Middle_Name')}`}>
        <InputText ref={midNameInputRef} id="MidName" name="MidName" value={formik.values.MidName} onChange={(e) => onNameChange(e, 'MidName')} />
      </CommonInputField>

      <div id={elementIDs.genderWrap} style={{ display: 'contents' }}>
        <CommonInputField
          className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
          required
          label={t('Gender')}
          direction={genderDir === 'row' ? 'row' : 'column'}
          style={genderDir === 'row' && { height: '70px' }}
          errorMessage={getFormErrorMessage('Sex')}
        >
          <div className="formgroup-inline align-items-center" style={{ height: '51px' }}>
            <div className="field-checkbox mb-0">
              <RadioButton
                id={elementIDs.male}
                className="maleRadioButton"
                name="Sex"
                value="M"
                checked={formik.values.Sex === 'M'}
                onChange={formik.handleChange}
              />
              <label className="p-radiobutton-label">{t('Male.2')}</label>
            </div>

            <div className="field-checkbox mb-0">
              <RadioButton
                id={elementIDs.female}
                className="femaleRadioButton"
                name="Sex"
                value="F"
                checked={formik.values.Sex === 'F'}
                onChange={formik.handleChange}
              />
              <label className="p-radiobutton-label">{t('Female.2')}</label>
            </div>
          </div>
        </CommonInputField>

        <CommonInputField
          className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
          label={commonInputs.nickname.label}
        >
          <InputText
            id={commonInputs.nickname.name}
            name={commonInputs.nickname.name}
            value={formik.values?.[commonInputs.nickname.name]}
            onChange={formik.handleChange}
          />
        </CommonInputField>

        <CommonInputField
          className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
          label={commonInputs.accessWord.label}
        >
          <InputText
            keyfilter={integersAndLettersOnly}
            id={commonInputs.accessWord.name}
            name={commonInputs.accessWord.name}
            value={formik.values?.[commonInputs.accessWord.name]}
            onChange={formik.handleChange}
          />
        </CommonInputField>
      </div>

      <ReferralInput formik={formik} inputFieldClassName={inputFieldClassName} />

      <CommonInputField
        label="Deceased"
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        style={{ height: '70px', alignContent: 'center' }}
        direction="row"
      >
        <InputSwitch
          checked={formik.values.IsDeceased}
          onChange={(e) => {
            const isDeceased = e.value;
            formik.setFieldValue('IsDeceased', isDeceased);
            !isDeceased && formik.setFieldValue('DateDeceased', null);
            // isDeceasedDateFuture && setIsDeceasedDateFuture(false);
          }}
        />
      </CommonInputField>

      {formik.values.IsDeceased && (
        <CommonInputField
          label="Date Deceased"
          className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
          style={{ height: 'fit-content' }}
          errorMessage={isDeceasedDateFuture && <small className="p-error">{t('Future_date_not_allowed')}</small>}
        >
          <DatePicker
            isInvalid={isDeceasedDateFuture}
            value={formik.values.DateDeceased}
            maxDate={new Date()}
            onChange={(value) => {
              formik.setFieldValue('DateDeceased', value);
              // const isFutureDate = moment(value).isAfter(moment());
              // if (isFutureDate) {
              //   setIsDeceasedDateFuture(true);
              // } else {
              //   isDeceasedDateFuture && setIsDeceasedDateFuture(false);
              // }
            }}
          />
        </CommonInputField>
      )}

      <MismatchDialog
        isNew={true}
        header={showNameMismatch && t('MSP_Spelling')} // CMO-1688 - Add Name Mismatch to Create Patient screen
        patient_details={formik.values}
        eligibility={eligibility}
        showMismatchDialog={showMismatchDialog || showNameMismatch}
        differentPatientsDemographics={differentPatientsDemographics}
        updateFormikState={updateFormikState}
        setShowMismatchDialog={showNameMismatch ? setShowNameMismatch : setShowMismatchDialog} // CMO-1688 - Add Name Mismatch to Create Patient screen
        setDifferentPatientsDemographics={setDifferentPatientsDemographics}
      />
    </>
  );
};

export default Demographics;
