import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatMoney, arrayUnique } from '../../../Helper';
import { getRemittanceUrl } from '../../../config';
import { formatDate } from '../../utils/formatDate';
import { currencyFormat } from '../../utils/currencyFormat';

const RowClaimHistory = (history, isMobileOnly) => {
  if (history && history.map === undefined) {
    history = Object.assign([], history);
  }

  if (history && history.map) {
    let Codes = [];
    const formatted = history.map((x) => {
      if (x.SDate) {
        const FeeAmount = x.Action.substring(0, 1) === 'R' ? x.FeePaid / x.UnitsPaid : x.FeeBilled / x.UnitsBilled;
        const Units = x.Action.substring(0, 1) === 'R' ? Math.abs(x.UnitsPaid) : Math.abs(x.UnitsBilled);
        const Debit = x.UnitsPaid.indexOf('-') > -1 || x.RecordType === 'DR';
        let codes = '';
        var codeText = [];
        if (x.RecordType === 'DR') {
          codes = x.ResponseCode;
        }
        if (x.MSPCodes && x.MSPCodes !== '' && x.MSPCodes !== '*') {
          codes += x.ResponseCode;
          const parts = x.MSPCodes.replace(/<br\/>/g, '').split(',');
          codeText = parts.map((e) => {
            return e.replace(/<b>/g, '').replace(/<\/b>/g, ': ').replace(/\[/g, '').replace(/\]/g, '');
          });
          Codes = Codes.concat(codeText);
        }
        if (x.AdjText && x.AdjText !== '') {
          codes += ' (' + x.AdjCodes + ')';
          codes = codes.replace('()', '');
          const parts = x.AdjText.replace(/<br\/>/g, '').split(',');
          codeText = parts.map((e) => {
            return e.replace(/<b>/g, '').replace(/<\/b>/g, ': ').replace(/\[/g, '').replace(/\]/g, '');
          });
          if (x.RecordType === 'DR') {
            codeText = `* User-initiated debit request #${x.SequenceNum}. Reason: ${codeText}`;
          }
          Codes = Codes.concat(codeText);
        }
        Codes = arrayUnique(Codes);
        if (x.Action.substring(0, 1) === 'R') {
          return {
            Debit,
            RecordType: x.RecordType,
            DCNGuid: x.DCNGuid,
            RowGuid: x.RowGuid,
            Date: { value: x.SDate.substring(0, 10), title: 'Date' },
            Action: { value: x.Action, title: 'Action' },
            Payer: { value: x.Insurer, title: 'Payer' },
            FeeCode: { value: `${x.ItemPaid} x ${Units}`, title: 'Fee Code' },
            Amount: { value: `${formatMoney(FeeAmount)} x ${Units} = ${formatMoney(Math.abs(x.FeePaid))}`, title: 'Amount' },
            AdjTotal: { value: x.AdjTotal, title: 'Adj' },
            Rural: { value: x.SccPaid !== '00' ? x.SccPaid : '', title: 'Rural' },
            ItemBilled: { value: x.ItemBilled },
            ItemPaid: { value: x.ItemPaid },
            MSPCodes: { value: codes, title: 'Expl. Codes' }
          };
        } else {
          if (x.RecordType === 'DR') {
            return {
              Debit,
              RecordType: x.RecordType,
              Date: { value: x.SDate.substring(0, 10), title: 'Date' },
              Action: { value: 'Debit Request', title: 'Action' },
              Payer: { value: '', title: 'Payer' },
              FeeCode: { value: '', title: 'Fee Code' },
              Amount: { value: '', title: 'Amount' },
              AdjTotal: { value: '', title: 'Adj' },
              Rural: { value: '', title: 'Rural' },
              ItemBilled: { value: '' },
              ItemPaid: { value: '' },
              MSPCodes: { value: codes, title: 'Expl. Codes' }
            };
          } else {
            return {
              Debit,
              RecordType: x.RecordType,
              Date: { value: x.SDate.substring(0, 10), title: 'Date' },
              Action: { value: x.Action, title: 'Action' },
              Payer: { value: x.Insurer, title: 'Payer' },
              FeeCode: { value: `${x.ItemBilled} x ${x.UnitsBilled}`, title: 'Fee Code' },
              Amount: { value: `${formatMoney(FeeAmount)} x ${x.UnitsBilled} = ${formatMoney(x.FeeBilled)}`, title: 'Amount' },
              AdjTotal: { value: x.AdjTotal, title: 'Adj' },
              Rural: { value: x.SccBilled !== '00' ? x.SccBilled : '', title: 'Rural' },
              ItemBilled: { value: x.ItemBilled },
              ItemPaid: { value: x.ItemPaid },
              MSPCodes: { value: codes, title: 'Expl. Codes' }
            };
          }
        }
      }
    });

    return isMobileOnly ? (
      <>
        {formatted.map((x, index) => {
          return (
            <div className="mb-2" key={`ptx_${index}`} style={{ borderBottom: '1px solid gray' }}>
              {Object.keys(x).map((i) =>
                x[i].title ? (
                  <div className="flex" key={x[i].title}>
                    <span className="col-4 pb-0">{x[i].title}:</span>
                    <div className="col-8 pb-0">{x[i].value}</div>
                  </div>
                ) : null
              )}
            </div>
          );
        })}
      </>
    ) : (
      <>
        {formatted.length > 0 && (
          <div>
            <DataTable
              value={formatted}
              autoLayout
              rows={history.length}
              lazy={false}
              paginatorPosition="none"
              responsive={true}
              displayField="value"
              totalRecords={history.length}
            >
              <Column field="Date" header="Date" body={(e) => smartCell(e, 'Date')} />
              <Column field="Action" header="Action" body={(e) => smartCell(e, 'Action')} />
              <Column field="Payer" header="Payer" body={(e) => smartCell(e, 'Payer')} />
              <Column field="FeeCode" header="Fee Code" body={smartFeeCodeTemplate} />
              <Column field="Amount" header="Amount" body={smartAmountTemplate} />
              <Column field="AdjTotal" header="Adj" body={(e) => smartCell(e, 'AdjTotal', true)} />
              <Column field="Rural" header="Rural" body={(e) => smartCell(e, 'Rural')} />
              <Column field="MSPCodes" header="Expl. Codes" body={(e) => smartCell(e, 'MSPCodes')} />
            </DataTable>

            <div style={{ marginTop: '1.5em' }}>{Codes.length > 0 && Codes.map((x, i) => <div key={`ptx_${i}`}>{x}</div>)}</div>
          </div>
        )}

        {formatted.length === 0 && <div>No history</div>}
      </>
    );
  } else {
    return null;
  }
};

const smartCell = (rowData, field, money) => {
  const remittanceReportURL = getRemittanceUrl();
  const linkTemplate = `${remittanceReportURL}/show.aspx?rt=r&DCNGuid=${rowData.DCNGuid}&id=${rowData.RowGuid}`;

  let value = money ? currencyFormat(rowData[field]?.value) : rowData[field]?.value;
  value = (value ?? '').trim().replace(/,+$/, '');
  const formattedValue = field === 'MSPCodes' ? value?.replaceAll(',', ', ') : value;
  const needLink = field === 'Date' && rowData.Action.value.substring(0, 1) === 'R';

  if (rowData.RecordType === 'DR' && money) return <span></span>;
  if (rowData.Debit) {
    if (needLink) {
      return (
        <span style={{ whiteSpace: 'nowrap', color: '#ff0000' }}>
          <a target="_blank" rel="noreferrer" href={linkTemplate}>
            {formatDate(formattedValue, 'YYYY-MM-DD', true)}
          </a>
        </span>
      );
    } else {
      return <span style={{ whiteSpace: 'nowrap', color: '#ff0000' }}>{formattedValue ? formattedValue : '—'}</span>;
    }
  } else {
    if (needLink) {
      return (
        <span style={{ whiteSpace: 'nowrap' }}>
          <a target="_blank" rel="noreferrer" href={linkTemplate}>
            {formatDate(formattedValue, 'YYYY-MM-DD', true)}
          </a>
        </span>
      );
    } else {
      return <span style={{ whiteSpace: 'nowrap' }}>{formattedValue ? formattedValue : '—'}</span>;
    }
  }
};

const smartFeeCodeTemplate = (rowData) => {
  if (rowData.RecordType === 'DR') return <span></span>;
  let codeStyle = { whiteSpace: 'nowrap' };
  if (rowData.ItemBilled.value !== rowData.ItemPaid.value && rowData.Action.value.substring(0, 1) === 'R') {
    codeStyle = { whiteSpace: 'nowrap', fontWeight: 'bold' };
  }
  if (rowData.Debit) {
    return <span style={{ whiteSpace: 'nowrap', color: '#ff0000' }}>-({rowData.FeeCode.value})</span>;
  } else {
    return <span style={codeStyle}>{rowData.FeeCode.value}</span>;
  }
};

const smartAmountTemplate = (rowData) => {
  if (rowData.RecordType === 'DR') return <span></span>;
  if (rowData.Debit) {
    const formatted = '-(' + rowData.Amount.value.replace(' = $', ') = -$');
    return <span style={{ whiteSpace: 'nowrap', color: '#ff0000' }}>{formatted}</span>;
  } else {
    return <span style={{ whiteSpace: 'nowrap' }}>{formatMoney(rowData.Amount.value)}</span>;
  }
};

export default RowClaimHistory;
